<template>
  <div class="authorization" style="overflow-y: hidden !important">
    <Loader />
  </div>
</template>

<script>
import Loader from "./components/loader";
import ip from "ip";
const publicIp = require("what-is-my-ip-address");

export default {
  components: {
    Loader,
  },
  data() {
    return {
      httpEndpoint:
        "https://api.listening.isocialcube.com/graphql" /* "http://localhost:3000/graphql" */,
    };
  },
  methods: {
    getToken() {
      return this.$route.query.token;
    },
    getRefresh() {
      return this.$route.query.refresh;
    },
    getIp() {
      return ip.address();
    },
    async getIpPublic() {
      return await publicIp.v4();
    },
    async queryPetition(endpoint, query, variables) {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          api_key: "a6e71e02cd9c6565c2ad2fbaf135bf64",
        },
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify({
          operationName: "validateJWT",
          query: query,
          variables: variables,
        }),
      });
      return response.json();
    },
    async validateToken(token, refresh, publicIp, ip) {
      const query = `query validateJWT($token: String,$refresh: String, $ip_public: String, $ip_local: String) {
        validateJWT(token: $token, refresh: $refresh, ip_public: $ip_public, ip_local: $ip_local) {
          code
          message
          messageError
          decode
          dateExpiredToken
          newToken
        }
      }
      `;
      const variables = {
        token: token,
        refresh: refresh,
        ip_public: publicIp,
        ip_local: ip,
      };

      return this.queryPetition(this.httpEndpoint, query, variables);
    },
  },
  async created() {
    try {
      const asyncSessionStorage = {
        setItem: function (key, value) {
          return Promise.resolve().then(function () {
            sessionStorage.setItem(key, value);
          });
        },
      };
      sessionStorage.clear();
      const tokenURL = this.getToken();
      const refreshURL = this.getRefresh();
      const ip = this.getIp();
      const publicIp = await this.getIpPublic();
      if (tokenURL && refreshURL) {
        const {
          data: { validateJWT },
        } = await this.validateToken(tokenURL, refreshURL, publicIp, ip);
        if (validateJWT.code != 0) throw new Error("Error validating tokens!");

        switch (validateJWT.code) {
          case 0:
            if (!validateJWT.decode.username)
              throw new Error("Error validating tokens!");
            const vueObject = this;
            /* sessionStorage.setItem("token", tokenURL);
            sessionStorage.setItem("refresh", refreshURL);
            sessionStorage.setItem("username", validateJWT.decode.username);
            sessionStorage.setItem("client_id", validateJWT.decode.id_cliente); */
            asyncSessionStorage.setItem("token", tokenURL).then(function () {
              vueObject.$store.dispatch("global/auth/setToken", tokenURL);
              asyncSessionStorage
                .setItem("refresh", refreshURL)
                .then(function () {
                  window.dispatchEvent(
                    new CustomEvent("tokens-changed", {
                      detail: {
                        token: sessionStorage.getItem("token"),
                        refresh: sessionStorage.getItem("refresh"),
                      },
                    })
                  );
                  vueObject.$store.dispatch("global/auth/setRefresh", refreshURL);
                  asyncSessionStorage
                    .setItem("client_id", validateJWT.decode.id_cliente)
                    .then(function () {
                      asyncSessionStorage
                        .setItem("username", validateJWT.decode.username)
                        .then(function () {
                          asyncSessionStorage
                            .setItem("name", validateJWT.decode.nombres)
                            .then(function () {
                              asyncSessionStorage
                                .setItem("ip_public", publicIp)
                                .then(function () {
                                  asyncSessionStorage
                                    .setItem("ip_local", ip)
                                    .then(function () {
                                      asyncSessionStorage
                                        .setItem("module", "design")
                                        .then(function () {
                                          window.dispatchEvent(
                                            new CustomEvent("user-changed", {
                                              detail: {
                                                name:
                                                  sessionStorage.getItem(
                                                    "name"
                                                  ),
                                                username:
                                                  sessionStorage.getItem(
                                                    "username"
                                                  ),
                                                module: sessionStorage.getItem(
                                                    "module"
                                                  ),
                                              },
                                            })
                                          );
                                          vueObject.$store.dispatch(
                                            "global/auth/setName",
                                            validateJWT.decode.nombres
                                          );
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });

            this.$router.push({ name: "/main" });
            break;
          case 1002:
            console.error("Expired Token!");
            break;
          default:
            throw new Error("Invalid Token!");
        }
      } else {
        throw new Error("Missing query string!");
      }
    } catch (error) {
      console.error(error);
      sessionStorage.clear();
      window.location.href = "https://monitor.isocialcube.com/login";
    }
  },
  mounted() {},
};
</script>

<style scoped>
</style>