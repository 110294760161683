<template>
  <div class="loaderTransveral">
    <div class="positionImgLoader">
      <img
        class="img-loader"
        src="https://listen-images.s3.us-east-2.amazonaws.com/isotipo_isc_140x164.svg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style scoped>
.loaderTransveral {
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #00a5ff;
}
.positionImgLoader {
  padding-top: 25%;
  padding-left: 45%;
}
.img-loader {
  width: 80px;
  animation: mymove 1.5s infinite;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}
@keyframes mymove {
  0% {
    width: 80px;
  }
  50% {
    width: 100px;
    margin-top: -20px;
    margin-left: -10px;
  }
  100% {
    width: 80px;
  }
}

@keyframes mymove2 {
}
</style>